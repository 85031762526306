<template>
  <div>
    <h2 class="text-xl font-weight-semibold">Detalhes</h2>

    <v-list>
      <v-list-item dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Nome:</span>
        <span class="text--secondary">{{ usuario.nome }}</span>
      </v-list-item>

      <v-list-item dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Filial:</span>
        <span
          v-if="
            usuario.relacionamento &&
            usuario.relacionamento.filial &&
            usuario.relacionamento.filial.nome
          "
          class="text--secondary"
        >
          {{ usuario.relacionamento.filial.nome }}
        </span>
        <v-chip v-else small color="warning">
          <v-icon class="mr-1">mdi-information-outline</v-icon> Sem filial
        </v-chip>
      </v-list-item>

      <v-list-item dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Setor:</span>
        <span
          v-if="
            usuario.relacionamento &&
            usuario.relacionamento.setor &&
            usuario.relacionamento.setor.nome
          "
          class="text--secondary"
        >
          {{ usuario.relacionamento.setor.nome }}
        </span>
        <v-chip v-else small color="warning">
          <v-icon class="mr-1">mdi-information-outline</v-icon> Sem setor
        </v-chip>
      </v-list-item>

      <v-list-item dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Cargo:</span>
        <span
          v-if="
            usuario.relacionamento &&
            usuario.relacionamento.cargo &&
            usuario.relacionamento.cargo.nome
          "
          class="text--secondary"
        >
          {{ usuario.relacionamento.cargo.nome }}
        </span>
        <v-chip v-else small color="warning">
          <v-icon class="mr-1">mdi-information-outline</v-icon> Sem cargo
        </v-chip>
      </v-list-item>

      <v-list-item dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Time:</span>
        <span
          v-if="
            usuario.relacionamento &&
            usuario.relacionamento.time &&
            usuario.relacionamento.time.nome
          "
          class="text--secondary"
        >
          {{ usuario.relacionamento.time.nome }}
        </span>
        <v-chip v-else small color="warning">
          <v-icon class="mr-1">mdi-information-outline</v-icon> Sem time
        </v-chip>
      </v-list-item>

      <v-list-item dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Gestor:</span>
        <span class="text--secondary">{{
          resolveUserGestor(usuario.gestor)
        }}</span>
      </v-list-item>
      <v-list-item dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Coordenador:</span>
        <span class="text--secondary">{{
          resolveUserCoordenandor(usuario.coordenador)
        }}</span>
      </v-list-item>

      <v-list-item dense class="px-0 mb-n2">
        <span class="font-weight-medium text-no-wrap me-2">E-mail:</span>
        <span class="text--secondary">{{ usuario.email }}</span>
      </v-list-item>

      <v-list-item dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Status:</span>
        <span class="text--secondary">
          <v-chip
            small
            label
            :color="resolveUserStatusVariant(usuario.status)"
            :class="`v-chip-light-bg ${resolveUserStatusVariant(
              usuario.status
            )}--text font-weight-medium text-capitalize`"
          >
            {{ usuario.status == "A" ? "Ativo" : "Inativo" }}
          </v-chip>
        </span>
      </v-list-item>

      <v-list-item dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Papel:</span>
        <span class="text--secondary text-capitalize">{{ usuario.papel }}</span>
      </v-list-item>
      <v-list-item dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Uid:</span>
        <span class="text--secondary">{{ usuario.uuid }}</span>
      </v-list-item>

      <v-list-item dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Celular:</span>
        <span v-if="usuario.celular" class="text--secondary">{{
          usuario.celular
        }}</span>
        <v-chip v-else small color="warning">
          <v-icon class="mr-1">mdi-information-outline</v-icon> Sem celular
        </v-chip>
      </v-list-item>

      <v-list-item dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Data nascimento:</span>
        <span class="text--secondary">{{
          usuario.aniversario | dateFormatBr()
        }}</span>
      </v-list-item>
    </v-list>

    <h2 class="text-xl font-weight-semibold mt-6">Endereço</h2>

    <v-list v-if="usuario && usuario.endereco">
      <v-list-item dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Uf:</span>
        <span class="text--secondary">{{ usuario.endereco.uf }}</span>
      </v-list-item>

      <v-list-item dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Cep:</span>
        <span class="text--secondary">{{
          resolveCep(usuario.endereco.cep)
        }}</span>
      </v-list-item>

      <v-list-item dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Cidade:</span>
        <span class="text--secondary">{{ usuario.endereco.cidade }}</span>
      </v-list-item>

      <v-list-item dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Bairro:</span>
        <span class="text--secondary">{{ usuario.endereco.bairro }}</span>
      </v-list-item>

      <v-list-item dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Rua:</span>
        <span class="text--secondary">{{ usuario.endereco.rua }}</span>
      </v-list-item>

      <v-list-item dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Nº:</span>
        <span class="text--secondary">{{ usuario.endereco.numero }}</span>
      </v-list-item>

      <v-list-item dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Complemento:</span>
        <span class="text--secondary">{{ usuario.endereco.complemento }}</span>
      </v-list-item>
    </v-list>
    <v-card v-else color="error" dark class="mt-2">
      <v-card-title>Endereço não cadastrado</v-card-title>
      <v-card-text>
        O endereço deste usuário não foi cadastrado. Por favor, cadastre um
        endereço para aparecer aqui.
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "UserTabOverview",
  methods: {
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");

      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },
    resolveUserRoleVariant(role) {
      if (role === "Desenvolvedor") return "primary";
      if (role === "Administrador") return "warning";
      if (role === "Recurso Humanos") return "success";
      if (role === "Gestor") return "info";
      if (role === "Operacional") return "error";
      if (role === "Comercial") return "error";

      return "primary";
    },
    resolveUserStatusVariant(status) {
      if (status === "P") return "warning";
      if (status === "A") return "success";
      if (status === "I") return "secondary";

      return "primary";
    },
    resolveUserGestor(value) {
      if (value === 0) return "Não";
      if (value === 1) return "Sim";

      return "Não";
    },
    resolveUserCoordenandor(value) {
      if (value === 0) return "Não";
      if (value === 1) return "Sim";

      return "Não";
    },
    resolveCep(value) {
      if (!value) return "";

      return value
        .replace(/\D/g, "")
        .replace(/(\d{5})(\d)/, "$1-$2")
        .replace(/(-\d{3})\d+?$/, "$1");
    },
  },
  computed: {
    usuario: {
      get() {
        return this.$store.getters["usuarios/getUsuario"];
      },
    },
  },
};
</script>

<style></style>
